<template>
  <layout-sub name="direction" sub-title="专注医疗大健康产业投资">
    <p class="p-direction-text">
      勤智资本与霁因生物医药转化（大湾区）基地、国家高性能医疗器械创新中心、上海欧美同学会生物医药分会、广东省医院协会等产业平台建立紧密的战略合作伙伴关系，充分利用政府资金对早期项目的扶持，持续导入社会资本，推动项目公司化和产业化。勤智资本在生物医药领域全产业链进行布局，上下游的产业资源涵盖研发、生产、流通各个环节，对可投资标的进行全生命周期服务。
    </p>
    <b-carousel ref="myCarousel" v-model="slideIndex" :interval="0" controls>
      <!-- Slides with image only -->
      <b-carousel-slide
        v-for="(el, index) in slideList"
        :key="index"
        :img-src="el.img"
      >
        <template #img>
          <div
            class="p-direction-img"
            :style="{ backgroundImage: `url('${el.img}')` }"
          />
        </template>
      </b-carousel-slide>
    </b-carousel>
    <div class="p-direction-nav">
      <b-card
        v-for="(el, index) in slideList"
        :key="index"
        :title="el.title"
        :body-class="`p-direction-menu p-3 p-xl-4${
          index === slideIndex ? ' current' : ''
        }`"
        border-variant="light"
        @click="onSlide(index)"
      >
        <b-card-text
          class="p-direction-text d-none d-md-block"
          v-html="el.detail"
        />
      </b-card>
    </div>
    <p class="p-direction-text d-md-none mt-3" v-html="slideText" />
  </layout-sub>
</template>

<script>
import LayoutSub from "@/components/LayoutSub";

export default {
  name: "Direction",
  components: { LayoutSub },
  data() {
    return {
      slideIndex: 0,
      slideText: "",
      slideList: [
        {
          title: "生物医药",
          detail:
            "聚焦创新生物药、新型基因治疗、细胞治疗、关注化药板块优质标的<br>重点投向肿瘤、心脑血管疾病、感染等大市场领域<br>其他生物医药产业链上下游优质标的，如CRO、CDMO企业等",
          img: require("@/assets/banner-direction-swyy.jpg"),
        },
        {
          title: "医疗器械",
          detail:
            "关注心血管、肾病等大病种慢病管理的相关领域，关注创新的微创手术治疗设备、骨科材料和高值耗材<br>在诊断产品领域，关注“精准医疗”相关的体外诊断设备与试剂<br>医疗器械上下游产业链相关的创新性项目",
          img: require("@/assets/banner-direction-ylqx.jpg"),
        },
        {
          title: "智慧医疗",
          detail:
            "关注新一代信息技术、新材料与医疗技术联合应用的标的<br>关于医改和医疗新政带来的医院混改、医联体等机会",
          img: require("@/assets/banner-direction-ylfw.jpg"),
        },
      ],
    };
  },
  mounted() {
    this.slideText = this.slideList[this.slideIndex].detail;
  },
  methods: {
    onSlide(index) {
      this.$refs.myCarousel.setSlide(index);
      this.slideText = this.slideList[index].detail;
    },
  },
};
</script>

<style lang="scss" scoped>
.p-direction {
  &-nav {
    display: flex;

    .card {
      flex: 1;
    }

    .card-title {
      margin-bottom: 0;
      font-size: $h5-font-size;
      text-align: center;
    }

    .card-text {
      color: $secondary;
    }
  }

  &-menu {
    border-bottom: 4px solid $light;
    background-color: $light;
    cursor: pointer;

    &.current {
      border-bottom-color: $primary;
      background-color: $white;
    }
  }

  &-img {
    height: 250px;
    background-color: $light;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;
  }

  @include media-breakpoint-up(md) {
    &-nav {
      .card-title {
        margin-bottom: $spacer;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &-img {
      height: 450px;
    }
  }

  @include media-breakpoint-up(xl) {
    &-nav {
      .card-title {
        font-size: $h4-font-size;
      }
    }

    &-text {
      font-size: $font-size-lg;
    }
  }
}
</style>
